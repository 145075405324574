/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const HeroSection = ({ data }) => {
  return (
    <>
      <div className="surface-0 flex w-full justify-center">
        <div
          id="home"
          className="landing-wrapper w-full justify-center overflow-hidden"
        >
          {data.fields.Photos ? (
            <div
              id="hero"
              className="flex flex-col overflow-hidden px-4 pt-4 lg:px-8"
            >
              <div className="container m-auto flex items-center justify-center ">
                <div className="relative w-full pt-[67%] md:pt-[42%]">
                  <div className="absolute inset-0 flex items-center justify-center">
                    <img
                      src={data.fields.Photos[0].url}
                      alt=""
                      className="h-full w-full rounded-[24px] object-cover"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default HeroSection;
