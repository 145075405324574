/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { scroller } from "react-scroll";
import axios from "axios";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Sidebar } from "primereact/sidebar";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { AutoComplete } from "primereact/autocomplete";
import { SELECT_NEIGHBORHOOD_DATA } from "../redux/type";
import "./style.css";
// import { Calendar } from "primereact/calendar";
// import { InputTextarea } from "primereact/inputtextarea";
// import { InputNumber } from "primereact/inputnumber";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const headerArr = useSelector((state) => state.headerData);
  // const neighborhoods = useSelector((state) => state.neighborhoods);

  const [visible, setVisible] = useState(false);
  const [sideVisible, setSideVisible] = useState(false);
  const [selectedNeighbor, setSelectedNeighbor] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [addEventModal, setAddEventModal] = useState(false);
  const [eventName, setEventName] = useState("");
  const [calendarUrl, setCalendarUrl] = useState("");
  const [neighborCategory, setNeighborCategory] = useState([]);
  const [selectedAges, setSelectedAges] = useState([]);
  const [filteredAges, setFilteredAges] = useState();
  const [itemArr, setItemArr] = useState([]);
  // const [selectedNeighborhood, setSelectedNeighborhood] = useState();
  // const [startDate, setStartDate] = useState();
  // const [endDate, setEndDate] = useState();
  // const [startTime, setStartTime] = useState();
  // const [endTime, setEndTime] = useState();
  // const [location, setLocation] = useState();
  // const [details, setDetails] = useState();
  // const [orgName, setOrgName] = useState();
  // const [ticketPrice, setTicketPrice] = useState();
  // const [neighborhoodData, setNeighborhoodData] = useState([]);
  // const [filteredNeighborhood, setFilteredNeighborhood] = useState(null);`

  const toast = useRef(null);

  useEffect(() => {
    const hData = headerArr.headerArr;
    if (hData[0]) {
      let itemNeighbor = [];
      setNeighborCategory(hData);
      hData.map((item) => {
        itemNeighbor.push({
          label: (
            <div onClick={(e) => handleSelectNeighborhood(e)}>{item.title}</div>
          ),
          icon: "",
        });
      });
      setItemArr(itemNeighbor);
    }
  }, [headerArr]);

  // useEffect(() => {
  //   setNeighborhoodData(neighborhoods.neighborhoods);
  // }, [neighborhoods]);

  const items = [
    {
      label: "Popular Neighborhoods",
      icon: "",
      items: itemArr,
    },
    {
      label: (
        <button
          className="font-extrabold"
          onClick={() => {
            setAddEventModal(true);
            setSideVisible(false);
          }}
        >
          Add Calendar
        </button>
      ),
      icon: "pi pi-calendar-plus",
    },
    {
      label: (
        <button
          className="font-extrabold"
          onClick={() => {
            setVisible(true);
            setSideVisible(false);
          }}
        // style={{ color: "blue" }}
        >
          Stay Informed
        </button>
      ),
      icon: "pi pi-sign-in",
    },
  ];

  const handleSelectNeighborhood = (e) => {
    const currentPath = window.location.pathname;
    dispatch({ type: SELECT_NEIGHBORHOOD_DATA, payload: e.target.innerHTML });
    setSideVisible(false);
    if (currentPath !== "/faq") {
      scroller.scrollTo("hero_section", {
        spy: true,
        smooth: true,
        offset: -100,
        duration: 500,
      });
    } else {
      navigate(`/`);
      scroller.scrollTo("hero_section", {
        spy: true,
        smooth: true,
        offset: -100,
        duration: 500,
      });
    }
  };

  const handleStayinformed = () => {
    if (
      !firstName ||
      !lastName ||
      !email ||
      !selectedNeighbor ||
      !selectedAges[0]
    ) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please fill all forms",
        life: 3000,
      });
    } else {
      try {
        setLoading(true);
        const sendData = {
          firstName: firstName,
          lastName: lastName,
          email: email,
          neighborhood: selectedNeighbor.title,
          kidAge: selectedAges.map((age) => age.kidAge),
        };

        axios
          .post(`${process.env.REACT_APP_API_URL}adduser`, sendData)
          .then((res) => {
            setLoading(false);
            setVisible(false);
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: res.data.message,
              life: 3000,
            });
            setFirstName(null);
            setLastName(null);
            setEmail(null);
            setSelectedNeighbor(null);
            setSelectedAges([]);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "There are some server error.",
              life: 3000,
            });
          });
      } catch (error) {
        setLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "There are some server error.",
          life: 3000,
        });
      }
    }
  };

  const handleAddEvent = () => {
    if (
      !email ||
      !firstName ||
      !eventName ||
      !calendarUrl
      // !startDate ||
      // !endDate ||
      // !selectedNeighborhood ||
      // !ticketPrice ||
      // !details
    ) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please fill all required fields",
        life: 3000,
      });
    } else {
      try {
        setLoading(true);
        const sendData = {
          firstName: firstName,
          lastName: lastName,
          email: email,
          eventName: eventName,
          calendarUrl: calendarUrl,
          // eventStartDate: startDate,
          // eventEndDate: endDate,
          // eventStartTime: startTime,
          // eventEndTime: endTime,
          // city: selectedNeighborhood?.City,
          // neighborhood: selectedNeighborhood?.Neighborhood,
          // zipcode: selectedNeighborhood?.Zip_Code,
          // state: selectedNeighborhood?.State,
          // orgName: orgName,
          // ticketPrice: ticketPrice,
          // details: details,
        };

        axios
          .post(`${process.env.REACT_APP_API_URL}addevent`, sendData)
          .then((res) => {
            setLoading(false);
            setAddEventModal(false);
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: res.data.message,
              life: 3000,
            });
            setFirstName("");
            setLastName("");
            setEmail("");
            setEventName("");
            setCalendarUrl("");
            // setStartDate(null);
            // setEndDate(null);
            // setStartTime(null);
            // setEndTime(null);
            // setDetails(null);
            // setOrgName(null);
            // setTicketPrice(null);
            // setSelectedNeighborhood(null);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "There are some server error.",
              life: 3000,
            });
          });
      } catch (error) {
        setLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "There are some server error.",
          life: 3000,
        });
      }
    }
  };

  const ages = [
    { kidAge: 1 },
    { kidAge: 2 },
    { kidAge: 3 },
    { kidAge: 4 },
    { kidAge: 5 },
    { kidAge: 6 },
    { kidAge: 7 },
  ];

  const search = (event) => {
    // Timeout to emulate a network connection
    setTimeout(() => {
      let _filteredAges;

      if (!event.query.trim().length) {
        _filteredAges = [...ages];
      } else {
        _filteredAges = ages.filter((age) => {
          return age.kidAge.toString().startsWith(event.query);
        });
      }

      setFilteredAges(_filteredAges);
    }, 250);
  };

  // const searchNeighborhood = (event) => {
  //   // Timeout to emulate a network connection
  //   setTimeout(() => {
  //     let _filteredNeighborhood;
  //     let _filterNeighborhoods = [];
  //
  //     if (!event.query.trim().length) {
  //       _filteredNeighborhood = [...neighborhoodData];
  //     } else {
  //       _filteredNeighborhood = neighborhoodData.filter((item) => {
  //         return item.fields.Zip_Code.startsWith(event.query);
  //       });
  //     }
  //
  //     _filteredNeighborhood.map((item) => {
  //       _filterNeighborhoods.push(item.fields);
  //     });
  //
  //     setFilteredNeighborhood(_filterNeighborhoods);
  //   }, 250);
  // };

  return (
    <>
      <header className="sticky top-0 z-50 w-full bg-white shadow-lg">
        <div className="m-auto grid h-[60px] grid-cols-12 items-center px-4">
          <Sidebar
            visible={sideVisible}
            position="right"
            onHide={() => setSideVisible(false)}
          >
            <Menu model={items} className="sidebar-menu mt-4 w-full" />
          </Sidebar>
          <div className="col-span-5 md:col-span-3">
            <Link to="/">
              <Image src="/eventsync_logo_blue.png" width="150" />
            </Link>
          </div>
          <div className="col-span-4 flex items-center justify-end md:col-span-6">
            <ul className="hidden list-none md:block">
              <li className="inline cursor-pointer">
                <div className="dropdown">
                  <button className="dropbtn">
                    <span>Popular Neighborhoods</span>
                    <i className="pi pi-chevron-down"></i>
                  </button>
                  <div className="dropdown-content max-h-[400px] overflow-auto">
                    {neighborCategory ? (
                      neighborCategory.map((e, index) => (
                        <a
                          onClick={(e) => handleSelectNeighborhood(e)}
                          key={index}
                        >
                          {e.title}
                        </a>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </li>
            </ul>
            <div className="signin flex justify-end pl-8">
              <div className="hidden md:block">
                <Button
                  label="Add Calendar"
                  severity="secondary"
                  size="middle"
                  outlined
                  onClick={() => {
                    setAddEventModal(true);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="signin col-span-3 flex justify-end">
            <div className="hidden md:block">
              <Button
                label="Stay Informed"
                severity="secondary"
                size="middle"
                outlined
                onClick={() => {
                  setVisible(true);
                }}
              />
            </div>
            <div className="block md:hidden">
              <Button
                className="!p-1"
                icon="pi pi-align-right"
                size="small"
                severity="secondary"
                outlined
                onClick={() => setSideVisible(true)}
              />
            </div>
          </div>
        </div>
      </header>

      <Dialog
        header={
          <div>
            <div className="News_logo">
              <i className="pi pi-envelope"></i>
            </div>
            <h1 className={`dialog_title text-center`}>STAY INFORMED</h1>
          </div>
        }
        visible={visible}
        onHide={() => {
          setVisible(false);
        }}
        className={`dialog_light News_Dialog w-1/2`}
      >
        <p className="description my-2 text-center">
          We send weekly emails
          <br />
          about the events in your neighborhood.
        </p>
        <div className="my-6 flex justify-between gap-2">
          <InputText
            className="p-input news_input text-sm"
            placeholder="First Name"
            onChange={(e) => setFirstName(e.target.value)}
          />
          <InputText
            className="p-input news_input text-sm"
            placeholder="Last Name"
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="my-6">
          <InputText
            className="p-input w-full text-sm"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="my-6">
          <Dropdown
            className="p-input w-full text-sm"
            placeholder="Your Neighborhood"
            options={neighborCategory}
            optionLabel="title"
            onChange={(e) => setSelectedNeighbor(e.value)}
            value={selectedNeighbor}
          />
        </div>
        <div className="my-6">
          <AutoComplete
            className="p-input p-fluid w-full text-sm"
            placeholder="Kids Ages(Optional)"
            field="kidAge"
            multiple
            value={selectedAges}
            suggestions={filteredAges}
            completeMethod={search}
            onChange={(e) => setSelectedAges(e.value)}
          />
        </div>
        <div className="my-6">
          <Button
            className="w-full"
            size="small"
            onClick={handleStayinformed}
            label={loading ? "loading..." : "SUBMIT"}
            disabled={loading}
          />
        </div>
        <p className="text-center opacity-30">
          <i>Your email is safe with us, we don't spam</i>
        </p>
      </Dialog>

      <Dialog
        header={
          <div>
            <h1 className={`dialog_title text-center`}>Suggest a Calendar</h1>
          </div>
        }
        visible={addEventModal}
        onHide={() => {
          setAddEventModal(false);
        }}
        className={`dialog_light News_Dialog w-1/2`}
      >
        <div className="my-6 flex justify-between gap-2">
          <InputText
            className="p-input news_input text-sm"
            placeholder="First Name *"
            onChange={(e) => setFirstName(e.target.value)}
          />
          <InputText
            className="p-input news_input text-sm"
            placeholder="Last Name"
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="my-6">
          <InputText
            className="p-input w-full text-sm"
            placeholder="Email Address*"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="my-6">
          <InputText
            className="p-input w-full text-sm"
            placeholder="Name of Calendar *"
            onChange={(e) => setEventName(e.target.value)}
          />
        </div>
        <div className="my-6">
          <InputText
            className="p-input w-full text-sm"
            placeholder="Url of Calendar *"
            onChange={(e) => setCalendarUrl(e.target.value)}
          />
        </div>
        {/*<div className="my-6 flex justify-between gap-2">*/}
        {/*  <Calendar*/}
        {/*    className="p-input news_input text-sm"*/}
        {/*    placeholder="Select start Date *"*/}
        {/*    onChange={(e) => setStartDate(e.value)}*/}
        {/*  />*/}
        {/*  <Calendar*/}
        {/*    className="p-input news_input text-sm"*/}
        {/*    placeholder="Select end Date *"*/}
        {/*    onChange={(e) => setEndDate(e.value)}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div className="my-6 flex justify-between gap-2">*/}
        {/*  <InputText*/}
        {/*    className="p-input news_input text-sm"*/}
        {/*    placeholder="Event Start Time 00:00 AM"*/}
        {/*    onChange={(e) => setStartTime(e.target.value)}*/}
        {/*  />*/}
        {/*  <InputText*/}
        {/*    className="p-input news_input text-sm"*/}
        {/*    placeholder="Event End Time 11:00 AM"*/}
        {/*    onChange={(e) => setEndTime(e.target.value)}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div className="my-6">*/}
        {/*  <AutoComplete*/}
        {/*    field="Zip_Code"*/}
        {/*    className="p-input w-full text-sm"*/}
        {/*    inputStyle={{ width: "100%" }}*/}
        {/*    value={selectedNeighborhood}*/}
        {/*    suggestions={filteredNeighborhood}*/}
        {/*    completeMethod={searchNeighborhood}*/}
        {/*    onChange={(e) => {*/}
        {/*      setSelectedNeighborhood(e.value);*/}
        {/*    }}*/}
        {/*    placeholder="Neighborhood Zipcode *"*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div className="my-6 flex justify-between gap-2">*/}
        {/*  <InputText*/}
        {/*    className="p-input news_input text-sm"*/}
        {/*    placeholder="Name of Organization"*/}
        {/*    onChange={(e) => setOrgName(e.target.value)}*/}
        {/*  />*/}
        {/*  <InputNumber*/}
        {/*    className="p-input news_input text-sm"*/}
        {/*    placeholder="Ticket Prices *"*/}
        {/*    mode="currency"*/}
        {/*    currency="USD"*/}
        {/*    locale="en-US"*/}
        {/*    value={ticketPrice}*/}
        {/*    onChange={(e) => setTicketPrice(e.value)}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div className="my-6 w-full">*/}
        {/*  <InputTextarea*/}
        {/*    autoResize*/}
        {/*    placeholder="Details *"*/}
        {/*    onChange={(e) => setDetails(e.target.value)}*/}
        {/*    rows={5}*/}
        {/*    className="w-full"*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="my-6">
          <Button
            className="w-full"
            size="small"
            onClick={handleAddEvent}
            label={loading ? "sending..." : "SEND"}
            disabled={loading}
          />
        </div>
      </Dialog>

      <Toast ref={toast} />
    </>
  );
};

export default Header;
