/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import ReCAPTCHA from "react-google-recaptcha";

const { format, addDays } = require("date-fns");

const DetailMainSection = (data) => {
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const toast = useRef(null);

  const submitHandler = () => {
    if (!name || !email || !message) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please fill all forms",
        life: 3000,
      });
    } else {
      try {
        setLoading(true);
        axios
          .post(`${process.env.REACT_APP_API_URL}email`, {
            name: name,
            fromEmail: email,
            toEmail: data.data.fields.Contact_Email_Address,
            message: message,
          })
          .then((res) => {
            setLoading(false);
            setVisible(false);
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: res.data.message,
              life: 3000,
            });
            setName(null);
            setEmail(null);
            setMessage(null);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "There are some SMTP server error.",
              life: 3000,
            });
          });
      } catch (error) {
        setLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "There are some SMTP server error.",
          life: 3000,
        });
      }
    }
  };

  const handleVerify = (response) => {
    if (response) {
      setIsVerified(true);
    }
  };

  return (
    <>
      <div className="flex w-full justify-center pt-8">
        <div className="item-center container relative flex h-full w-full flex-wrap justify-center gap-8 px-4 lg:gap-[60px]">
          {/* left part */}
          <div className="relative flex h-full w-full flex-col lg:w-auto lg:flex-1">
            {/* part 1 */}
            <div className="p-0">
              <h1 className="mb-8 break-words text-3xl font-extrabold text-violet-950 md:text-5xl">
                {data.data.fields.Name}
              </h1>
              <section className="mb-8 flex flex-row items-center break-words rounded-lg bg-slate-100 p-4">
                <div className="mb-0 mr-2 box-border flex flex-row items-center">
                  <div className="mr-auto block self-center">
                    <span className="text-base text-slate-500">
                      By
                      <strong className="ml-1 font-semibold text-violet-950">
                        {data.data.fields.Host_Organization}
                      </strong>
                    </span>
                  </div>
                </div>
              </section>
            </div>

            {/* part 2 */}
            <div className="sticky top-0 z-[1] box-border block"></div>

            {/* part 3 */}
            <section className="mb-8 box-border block break-words text-base text-violet-950">
              <div className="scroll-mt-[60px]">
                <div className=" mb-4 text-2xl font-bold text-violet-950">
                  <h2>When and where</h2>
                </div>
                <div className="mb-0 grid grid-cols-1 pt-2 md:grid-cols-2">
                  <section className="block basis-6/12 pr-8">
                    <div className="mb-6 box-border flex flex-wrap">
                      <span className="mr-[14px] inline-flex h-10 w-10 items-center rounded-lg bg-slate-100 p-2">
                        <i className="pi pi-calendar m-auto inline-block bg-contain align-middle" />
                      </span>
                      <div className="flex-1 text-sm text-slate-500">
                        <div className=" mb-2 text-lg font-semibold text-violet-950">
                          <h3>Date and time</h3>
                        </div>
                        <p className="mb-1">
                          <span>
                            {data.data.fields.Start_Date ===
                            data.data.fields.End_Date
                              ? data.data.fields.Start_Date
                                ? format(
                                    addDays(
                                      new Date(data.data.fields.Start_Date),
                                      1
                                    ),
                                    "M/d/y"
                                  ) +
                                  " ( " +
                                  (data.data.fields.Start_Time
                                    ? data.data.fields.Start_Time
                                    : "###") +
                                  " - " +
                                  (data.data.fields.End_Time
                                    ? data.data.fields.End_Time
                                    : "###") +
                                  " ) "
                                : "###"
                              : (data.data.fields.Start_Date
                                  ? format(
                                      addDays(
                                        new Date(data.data.fields.Start_Date),
                                        1
                                      ),
                                      "M/d/y"
                                    ) +
                                    " ( " +
                                    (data.data.fields.Start_Time
                                      ? data.data.fields.Start_Time
                                      : "###") +
                                    " ) "
                                  : "###") +
                                " ~ " +
                                (data.data.fields.End_Date
                                  ? format(
                                      addDays(
                                        new Date(data.data.fields.End_Date),
                                        1
                                      ),
                                      "M/d/y"
                                    ) +
                                    " ( " +
                                    (data.data.fields.End_Time
                                      ? data.data.fields.End_Time
                                      : "###") +
                                    " ) "
                                  : "###")}
                          </span>
                        </p>
                      </div>
                    </div>
                  </section>

                  <section className="relative block basis-6/12 pr-8">
                    <div className="mb-6 flex flex-wrap">
                      <span className="mr-[14px] inline-flex h-10 w-10 items-center rounded-lg bg-slate-100 p-2">
                        <i className="pi pi-compass m-auto inline-block bg-contain align-middle" />
                      </span>
                      <div className="flex-1 text-sm text-slate-500">
                        <div className=" mb-2 text-lg font-semibold text-violet-950">
                          <h3>Location</h3>
                        </div>
                        <div className="mb-1">
                          <div className="flex">
                            <p className="pr-1 text-violet-950">Address:</p>
                            {data.data.fields.Location}
                          </div>
                          <div className="flex">
                            <p className="pr-1 pt-2 text-violet-950">
                              Zipcode:
                            </p>
                            <p className="pt-2">{data.data.fields.Zip_Code}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <section className="mb-8 box-border block break-words text-base text-violet-950">
              <div className="scroll-mt-[60px]">
                <div className=" mb-4 text-2xl font-bold text-violet-950">
                  <h2>The Details</h2>
                </div>
                <div className="mb-0 flex pt-2">
                  <section className="blocks">
                    <div className="mb-6 box-border flex flex-wrap">
                      <div className="flex-1 text-sm text-slate-500">
                        <p className="mb-1">
                          <span>{data.data.fields.Event_Description}</span>
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            {/* part 4 */}
            <div>
              <section className="relative box-border block bg-white pb-5">
                <div className=" scroll-mt-[60px]">
                  <section>
                    <div className=" mb-4 text-2xl font-bold text-violet-950">
                      <h2>Host Organization</h2>
                    </div>
                    <div
                      className="mb-8 mt-8 h-auto rounded-lg bg-white p-10 text-center"
                      style={{
                        boxShadow:
                          "0 4px 15px 0 rgba(40,44,53,.06), 0 2px 2px 0 rgba(40,44,53,.08)",
                      }}
                    >
                      <div className="mx-auto my-6 flex items-center justify-center text-center">
                        <div className="relative max-w-full flex-1 px-4 py-0 text-sm">
                          <div className=" block text-xl font-bold text-violet-950">
                            {data.data.fields.Name
                              ? data.data.fields.Name
                              : "Event from Eventsync"}
                          </div>
                          <div className="flex justify-center text-sm text-slate-500">
                            <p className="pr-1">
                              {data.data.fields.Contact_First_Name
                                ? data.data.fields.Contact_First_Name
                                : "Admin"}
                            </p>
                            {data.data.fields.Contact_Last_Name
                              ? data.data.fields.Contact_Last_Name
                              : ""}
                          </div>
                          <div className="block text-sm text-slate-500">
                            {data.data.fields.Contact_Phone_Number
                              ? data.data.fields.Contact_Phone_Number
                              : ""}
                          </div>
                          <div className="text-sm text-slate-500">
                            {data.data.fields.Contact_Email_Address
                              ? data.data.fields.Contact_Email_Address
                              : ""}
                          </div>
                        </div>
                      </div>
                      <ul className="mb-0 flex list-none flex-row justify-center gap-5">
                        <li className="list-item text-center">
                          <button className=" relative h-11 cursor-pointer break-normal rounded px-[30px] py-[1px] text-center font-semibold leading-6 text-blue-600 no-underline">
                            Contact
                          </button>
                        </li>
                        <li className="list-item text-center">
                          <button
                            className="relative h-11 cursor-pointer break-normal rounded bg-blue-600 px-[30px] py-[1px] text-center font-semibold leading-6 text-white no-underline"
                            onClick={() => {
                              setVisible(true);
                            }}
                          >
                            Send Email
                          </button>
                        </li>
                      </ul>
                    </div>
                  </section>
                </div>
              </section>
            </div>
          </div>

          {/* right part */}
          <div className="relative w-full lg:w-[300px]">
            <div
              className="sticky top-8 mb-8 max-h-[140px] w-full items-center justify-center rounded-lg p-8"
              style={{ boxShadow: "0 0 0 1px #eeedf2" }}
            >
              <div className="w-full">
                <div className="overflow-hidden text-ellipsis whitespace-nowrap text-center text-lg font-semibold">
                  {data.data.fields.Price
                    ? "From $" + data.data.fields.Price
                    : "Free"}
                </div>
              </div>
              <div className="mt-2 box-border flex w-full auto-cols-fr grid-flow-col gap-2 text-sm">
                <Link
                  to={data.data.fields.Registration}
                  target="_blank"
                  className="relative box-border flex h-11 w-full items-center justify-center rounded bg-blue-600 px-[30px] py-[1px] font-semibold leading-6 tracking-wider text-white no-underline"
                >
                  Visit Website
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        header={
          <div>
            <div className="News_logo">
              <i className="pi pi-envelope"></i>
            </div>
            <h1 className={`dialog_title text-center`}>
              Inquiry from Eventsync.co
            </h1>
          </div>
        }
        visible={visible}
        onHide={() => {
          setVisible(false);
        }}
        className={`dialog_light News_Dialog w-1/2`}
      >
        <div className="my-6 flex justify-between gap-2">
          <InputText
            className="p-input news-input w-full text-sm"
            placeholder="Your Name"
            id="email"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="my-6 flex justify-between gap-2">
          <InputText
            className="p-input news-input w-full text-sm"
            placeholder="Your email address"
            id="email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="my-6 flex justify-between gap-2">
          <InputTextarea
            rows={5}
            cols={30}
            className="w-full"
            placeholder="Your Inquiry"
            id="message"
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY}
            onChange={handleVerify}
          />
        </div>
        <div className="my-6 flex justify-between gap-2">
          <Button
            className="w-full"
            onClick={submitHandler}
            label={loading ? "Sending..." : "Send Email"}
            disabled={loading || !isVerified}
          />
        </div>
        <p className="text-center opacity-30">
          <i>Your email is safe with us, we don't spam</i>
        </p>
      </Dialog>

      <Toast ref={toast} />
    </>
  );
};

export default DetailMainSection;
