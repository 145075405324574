import { SET_NEIGHBORHOODS } from "../type";

const initialState = {
  neighborhoods: [],
};

const neighborhoodsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_NEIGHBORHOODS:
      return { neighborhoods: payload };
    default:
      return state;
  }
};

export default neighborhoodsReducer;
