import { SELECT_NEIGHBORHOOD_DATA } from "../type";

const initialState = {
  headerData: "All",
};

const selectedHeaderReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SELECT_NEIGHBORHOOD_DATA:
      return { headerData: payload };
    default:
      return state;
  }
};

export default selectedHeaderReducer;
