import { combineReducers } from "@reduxjs/toolkit";

import headerReducer from "./headerDataReducer";
import selectedReducer from "./selectedHeaderReducer";
import neighborhoodsReducer from "./neighborhoodsReducer";

const mainReducer = combineReducers({
  headerData: headerReducer,
  selectedHeader: selectedReducer,
  neighborhoods: neighborhoodsReducer,
});

export default mainReducer;
