import Airtable from "airtable";
// Authenticate
Airtable.configure({
  apiKey: process.env.REACT_APP_AIRTABLE_ACCESS_TOKEN,
});

// Initialize a base
const base = Airtable.base(process.env.REACT_APP_AIRTABLE_BASE_ID);

// Reference a table
export const main_table = base(process.env.REACT_APP_AIRTABLE_DATA_TABLE_NAME);
export const user_table = base(process.env.REACT_APP_AIRTABLE_USERTABLE);
export const neighborhood_table = base(process.env.REACT_APP_AIRTABLE_NEIGHBORHOODTABLE);
