/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const HeroSection = () => {
  return (
    <>
      <div className="surface-0 flex w-full justify-center">
        <div
          id="home"
          className="landing-wrapper w-full justify-center overflow-hidden"
        >
          <div
            id="hero"
            className="flex flex-col overflow-hidden"
            style={{
              clipPath: "ellipse(150% 87% at 93% 13%)",
            }}
          >
            <div className="flex w-full justify-center">
              <div className="relative w-full pt-[60%] md:pt-[40%]">
                <div className="absolute inset-0 flex items-center justify-center">
                  <img
                    src="/hero.webp"
                    alt="Hero Image"
                    className="h-full w-full object-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
