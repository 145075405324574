/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Link } from "react-scroll";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  SELECT_NEIGHBORHOOD_DATA,
  SET_NEIGHBORHOOD_DATA,
} from "../../redux/type";
import { isEmpty } from "lodash";

const { format, addDays } = require("date-fns");

const MainSection = ({ datas, loading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const headerData = useSelector((state) => state.selectedHeader);

  const [date, setDate] = useState(null);
  const [eventData, setEventData] = useState([]);
  const [neighborCategory, setNeighborCategory] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedNeighbor, setSelectedNeighbor] = useState("All");
  const [cityData, setCityData] = useState([]);

  useEffect(() => {
    let cityArr = [];
    let filterCityArr;
    let resultCityArr = [{ name: "All", code: "All" }];
    let cityDataArr;
    let neighborDataArr = [{ title: "All" }];
    let neighborArr = [];
    let filterNeighborDataArr;

    datas.map((data) => {
      if (
        data.fields.City !== undefined &&
        addDays(new Date(data.fields.End_Date), 1).getTime() >=
        new Date().getTime()
      ) {
        cityArr.push(data.fields.City);
      }
    });

    filterCityArr = cityArr.filter(
      (item, index) => cityArr.indexOf(item) === index
    );

    filterCityArr.map((e) => {
      resultCityArr.push({ name: e, code: e });
    });
    setCityData(resultCityArr);

    cityDataArr = datas.filter(
      (e) =>
        (
          selectedCity === null || selectedCity.name === "All" ? 
          true
          : 
          e.fields.City === selectedCity.name
        ) 
        &&
        (
          selectedNeighbor === null || selectedNeighbor === "All" ? 
          true
          : 
          e.fields.Neighborhood[0] === selectedNeighbor
        ) 
        &&
        (
          date !== null ?
          new Date(date).getTime() >= new Date(e.fields.Start_Date).getTime() && addDays(new Date(e.fields.End_Date), 1).getTime() >= new Date(date).getTime()
          : 
          addDays(new Date(e.fields.End_Date), 1).getTime() >= new Date().getTime()
        )
    );

    cityDataArr = cityDataArr.filter(item => !isEmpty(item.fields.Photos))
    setEventData(cityDataArr.sort((a, b) => new Date(a.fields.Start_Date) - new Date(b.fields.Start_Date)));

    datas.filter((e) => {
      if (
        e.fields.Neighborhood[0] !== undefined 
        &&
        (
          selectedCity === null || selectedCity.name === "All" ? 
          true
          : 
          e.fields.City === selectedCity.name
        )
        &&
        addDays(new Date(e.fields.End_Date), 1).getTime() >= new Date().getTime()
        &&
        !isEmpty(e.fields.Photos)
      ) {
        neighborArr.push(e.fields.Neighborhood[0]);
      }
    });
    // console.log(neighborArr);
    let count = {};
    neighborArr.forEach(function (i) {
      count[i] = (count[i] || 0) + 1;
    });

    let sortable = [];
    for (let key in count) {
      sortable.push([key, count[key]]);
    }

    sortable.sort(function (a, b) {
      return b[1] - a[1];
    });
    let headerArr = [{ title: "All" }];
    sortable.slice(0, 5).map((item) => {
      headerArr.push({ title: item[0] });
    });

    filterNeighborDataArr = neighborArr.filter(
      (item, index) => neighborArr.indexOf(item) === index
    );
    filterNeighborDataArr.map((e) => {
      neighborDataArr.push({ title: e });
    });
    setNeighborCategory(neighborDataArr);
    dispatch({ type: SET_NEIGHBORHOOD_DATA, payload: headerArr });
  }, [datas, selectedCity, selectedNeighbor, date]);

  useEffect(() => {
    setSelectedNeighbor(headerData.headerData);
  }, [headerData]);

  const handleNeighbor = (e) => {
    dispatch({ type: SELECT_NEIGHBORHOOD_DATA, payload: e.target.outerText });
  };

  return (
    <>
      <div className="container m-auto flex items-center justify-between">
        <div className="card w-full py-4">
          <div className="search_box relative my-12 flex w-full items-center rounded-xl border">
            <div className="w-[120px] !min-w-[120px] !max-w-[120px] grow border-r p-2 text-lg font-bold outline-none focus:outline-none md:p-4">
              Popular In
            </div>
            <>
              <Dropdown
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.value)}
                options={cityData}
                optionLabel="name"
                placeholder="Events in your city"
                filter
                className="city_search w-[calc((100%-150px)/2)]"
              />
              <Calendar
                value={date}
                className="search_date w-[calc((100%-150px)/2)] !min-w-[140px] !max-w-[140px] grow border-l"
                onChange={(e) => setDate(e.value)}
                dateFormat="mm/dd/yy"
                placeholder="Select a date"
                showButtonBar
              />
            </>
          </div>

          <h2 className="pb-2 text-3xl font-bold text-slate-900">
            Neighborhoods Category
          </h2>

          {/*<div className="grid grid-cols-1 justify-between pb-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">*/}
          <div className="flex flex-wrap pb-10">
            {neighborCategory.map((category, index) => (
              <div className="p-2" key={index}>
                <div
                  className={`${selectedNeighbor === category.title ? "active_category" : ""
                    } rounded-[25px] px-2`}
                  style={{
                    background:
                      "linear-gradient(90deg, rgba(145,226,237,0.2),rgba(251, 199, 145, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(172, 180, 223, 0.2))",
                  }}
                >
                  <Link
                    to="hero_section"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                    className="surface-card flex h-full cursor-pointer flex-row rounded-lg p-2"
                    onClick={(e) => handleNeighbor(e)}
                  >
                    <h5 className="text-900 items-center justify-center">
                      {category.title}
                    </h5>
                  </Link>
                </div>
              </div>
            ))}
          </div>

          <h2
            className="pb-2 text-3xl font-bold text-slate-900"
            id="hero_section"
          >
            Events We Found
          </h2>

          <div className="card grid grid-cols-1 justify-between pb-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {eventData ? (
              eventData.map((data, index) => (
                <div className="p-2" key={index}>
                  <Card
                    // title={data.fields.Name ? data.fields.Name : "###"}
                    header={
                      <div className="relative w-full pt-[56%]">
                        <div className="absolute inset-0 flex items-center justify-center">
                          <img
                            alt="Card"
                            onClick={() => {
                              navigate(`/detail/${data.id}`);
                            }}
                            src={
                              data.fields.Photos
                                ? data.fields.Photos[0].url
                                : "/event_landing.png"
                            }
                            className="h-full w-full rounded-[8px] object-cover lg:rounded-[8px] cursor-pointer"
                          />
                        </div>
                      </div>
                    }
                    className="md:w-25rem h-full"
                  >
                    <div className="pb-8">
                      <a
                        className="text-2xl font-bold hover:text-gray-400"
                        href={`detail/${data.id}`}
                      >
                        {data.fields.Name ? data.fields.Name : "###"}
                      </a>
                    </div>
                    <p className="pb-2 text-blue-800">
                      {data.fields.Start_Date === data.fields.End_Date
                        ? data.fields.Start_Date
                          ? format(
                            addDays(new Date(data.fields.Start_Date), 1),
                            "M/d/y"
                          )
                          : "###"
                        : (data.fields.Start_Date
                          ? format(
                            addDays(new Date(data.fields.Start_Date), 1),
                            "M/d/y"
                          )
                          : "###") +
                        " ~ " +
                        (data.fields.End_Date
                          ? format(
                            addDays(new Date(data.fields.End_Date), 1),
                            "M/d/y"
                          )
                          : "###")}
                    </p>
                    <div className="pb-4 text-gray-800">
                      <div className="m-0 cursor-pointer" onClick={() => {
                        navigate(`/detail/${data.id}`);
                      }}>
                        {data.fields.Location ? data.fields.Location : "###"}
                      </div>
                      <div className="m-0 flex items-center pt-2 cursor-pointer" onClick={() => {
                        navigate(`/detail/${data.id}`);
                      }}>
                        <div className="pr-1">Starts at</div>
                        {data.fields.Price ? "$" + data.fields.Price : "free"}
                      </div>
                    </div>
                    <div className="flex break-all text-gray-800">
                      {data.fields.Source_of_Data ? (
                        <div>
                          <a
                            href={data.fields.Source_of_Data}
                            target="_blank"
                            rel="noreferrer"
                            className="hover:text-gray-400 hover:underline hover:underline-offset-4"
                          >
                            {`See Source`}
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Card>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="text-center">{loading ? <ProgressSpinner /> : <></>}</div>
    </>
  );
};

export default MainSection;
