import { Routes, Route } from "react-router-dom";

import Home from "../pages/Home";
import Detail from "../pages/Detail";
import FAQ from "../pages/FAQ";

const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/detail/:id" element={<Detail />} />
      <Route path="/faq" element={<FAQ />} />
    </Routes>
  );
};

export default AllRoutes;
