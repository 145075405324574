import "./App.css";
import AllRoutes from "./Routes/AllRoutes";
import { Provider } from "react-redux";
import { store } from "./redux/store";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

function App() {
  return (
    <Provider store={store}>
      <div>
        <AllRoutes />
      </div>
    </Provider>
  );
}

export default App;
