import React from "react";

import Header from "../../Layout/Header";
import HeroSection from "../../components/Hero/HeroSection";
import FaqMainSection from "../../components/FaqMain/FaqMainSection";

const Home = () => {
  return (
    <>
      <Header />
      <main>
        <HeroSection />
        <FaqMainSection />
      </main>
    </>
  );
};

export default Home;
