import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import Header from "../../Layout/Header";
import HeroSection from "../../components/Hero/HeroSection";
import MainSection from "../../components/Main/MainSection";
import { main_table, neighborhood_table } from "../../utils/airtable";
import { SET_NEIGHBORHOODS } from "../../redux/type";

const Home = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [allNeighborhoods, setAllNeighborhoods] = useState([]);

  useEffect(() => {
    getFetchData();
  }, []);

  useEffect(() => {
    dispatch({ type: SET_NEIGHBORHOODS, payload: allNeighborhoods });
  }, [dispatch, allNeighborhoods]);

  const getFetchData = () => {
    setLoading(true);
    const pageSize = 80;
    const today = new Date();
    const fourWeeksLater = new Date(today.getTime() + 28 * 24 * 60 * 60 * 1000);
    // const startDate = today.toISOString().split('T')[0];
    const endDate = fourWeeksLater.toISOString().split("T")[0];
    const filterFormula = `{Start_Date} <= '${endDate}'`;
    const options = { pageSize: pageSize, filterByFormula: filterFormula };
    neighborhood_table
      .select({
        pageSize: pageSize,
        filterByFormula: "LEN(Zip_Code) = 5",
      })
      .eachPage(
        function page(_records, fetchNextPage) {
          setAllNeighborhoods((prev_data) => [...prev_data, ..._records]);
          fetchNextPage();
        },
        function done(error) {
          if (error) {
            console.log(error);
          } else {
          }
        }
      );
    main_table.select(options).eachPage(
      function page(_records, fetchNextPage) {
        setAllData((prev_data) => [...prev_data, ..._records]);
        fetchNextPage();
      },
      function done(error) {
        if (error) {
        } else {
          setLoading(false);
        }
      }
    );
  };

  return (
    <>
      <div>
        <Header />
        <main>
          <HeroSection />
          {allData && <MainSection datas={allData} loading={loading} />}
        </main>
      </div>
    </>
  );
};

export default Home;
