import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import Header from "../../Layout/Header";
import DetailHeroSection from "../../components/DetailHero/DetailHeroSection";
import DetailMainSection from "../../components/DetailMain/DetailMainSection";

import { ProgressSpinner } from "primereact/progressspinner";

const Detail = () => {
  const { id } = useParams();
  const [viewData, setViewData] = useState();
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    setLoading(true);
    axios
    .post(`${process.env.REACT_APP_API_URL}get_detail_event`,
      {id: id}
    )
    .then((result) => {
      setViewData(result.data);
      setLoading(false);
    })
    .catch((error) => {
      console.log(error);
      setLoading(false);
    });
  }, [id]);

  return (
    <>
      {loading ? (
        <div className="flex h-screen w-screen items-center justify-center bg-transparent">
          <ProgressSpinner />
        </div>
      ) : (
        <div>
          <Header />
          <main>
            {viewData && <DetailHeroSection data={viewData} />}
            {viewData && <DetailMainSection data={viewData} />}
          </main>
        </div>
      )}
    </>
  );
};

export default Detail;
